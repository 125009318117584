import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Spin } from 'antd';
import axios from 'axios';
import { ArrowLeftOutlined } from '@ant-design/icons';

export default function Calendar() {
    const [employees, setEmployees] = useState([]);
    const [availability, setAvailability] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isChangingManager, setIsChangingManager] = useState(false);
    const [selectedManager, setSelectedManager] = useState(null);
    const [selectedDate, setSelectedDate] = useState(() => {
        const currentDate = new Date().toISOString().split('T')[0];
        return localStorage.getItem('selectedDate') || currentDate;
    });
    const [employeesLoaded, setEmployeesLoaded] = useState(false);

    const today = new Date();
    const currentDay = today.getDate();
    const currentMonth = today.getMonth() + 1;
    const currentYear = today.getFullYear();

    const location = useLocation();

    const formatDateForBitrix = (isoDate) => {
        const [year, month, day] = isoDate.split('-');
        return `${day}.${month}.${year}`;
    };

    const getFirstAndLastDayOfMonth = (date) => {
        const firstDay = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1));
        const lastDay = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 1, 0));
        return {
            firstDay: formatDateForBitrix(firstDay.toISOString().split('T')[0]),
            lastDay: formatDateForBitrix(lastDay.toISOString().split('T')[0])
        };
    };

    // Перемещаем `getPropertyValues` и `parseDate` сюда
    const getPropertyValues = (property) => {
        if (property == null) {
            return [];
        }
        return Object.values(property);
    };

    const parseDate = (dateString) => {
        const [datePart, timePart, ampm] = dateString.split(' ');
        const [day, month, year] = datePart.split('.');
        const [hours, minutes, seconds] = timePart.split(':');

        let hours24 = parseInt(hours);
        if (ampm.toLowerCase() === 'pm' && hours24 < 12) {
            hours24 += 12;
        } else if (ampm.toLowerCase() === 'am' && hours24 === 12) {
            hours24 = 0;
        }

        return new Date(year, month - 1, day, hours24, minutes, seconds);
    };

    useEffect(() => {
        localStorage.setItem('selectedDate', selectedDate);
        const { firstDay, lastDay } = getFirstAndLastDayOfMonth(new Date(selectedDate));
        if (selectedManager && employeesLoaded) {
            fetchAvailability(firstDay, lastDay, selectedManager);
        }
    }, [selectedDate, selectedManager, employeesLoaded]);

    useEffect(() => {
        fetchEmployees();
    }, []);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const managerId = query.get('id');
        if (managerId) {
            setSelectedManager(parseInt(managerId));
        }
    }, [location.search]);

    const fetchEmployees = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_GetAllBitrixUsers);
            setEmployees(response.data);
            setEmployeesLoaded(true);
        } catch (error) {
            console.log("Error fetching employees:", error);
        }
    };

    const fetchAvailability = async (startDate, endDate, managerId) => {
        setLoading(true);
        let results = [];
        const IBLOCK_TYPE_ID = "lists_socnet";
        const IBLOCK_ID = 187;

        const currentEmployees = employees
            .filter(employee => employee.manager === managerId)
            .map(employee => employee.value);

        if (currentEmployees.length === 0) {
            setAvailability([]);
            setLoading(false);
            return;
        }

        const FILTER = {
            // "PROPERTY_915": 6837,
            ">PROPERTY_910": `${startDate} 00:00:00 am`,
            "<=PROPERTY_910": `${endDate} 11:59:59 pm`,
            "PROPERTY_912": currentEmployees
        };

        const getElements = async (start = 0) => {
            try {
                const response = await axios.post(process.env.REACT_APP_API_TimeTracking, {
                    "IBLOCK_TYPE_ID": IBLOCK_TYPE_ID,
                    "IBLOCK_ID": IBLOCK_ID,
                    "FILTER": FILTER,
                    "start": start
                });
                
                results = results.concat(response.data.result);

                if (response.data.result.length < 50 || start >= response.data.total) {
                    setAvailability(results);
                    setLoading(false);
                    return results;
                } else {
                    return getElements(start + 50);
                }
            } catch (error) {
                console.log("Error fetching availability:", error);
                setLoading(false);
                return [];
            }
        };

        await getElements();
    };

    // const filterByEarliestClockIn = (availability) => {
    //     const earliestClockInEvents = {};
    
    //     availability.forEach(event => {
    //         const employeeIds = getPropertyValues(event.PROPERTY_912).map(String); // Получаем идентификаторы сотрудников
    //         const location = getPropertyValues(event.PROPERTY_918)[0]; // Получаем местоположение ('inside' или 'outside')
    //         const eventId = event.ID; // Получаем ID события
    
    //         // Фильтруем только события clock-in (например, 'inside' или 'office')
    //         if (location === 'inside' || location === 'office') {
    //             employeeIds.forEach(employeeId => {
    //                 // Если это первое событие для сотрудника или его ID меньше, то обновляем
    //                 if (!earliestClockInEvents[employeeId] || eventId < earliestClockInEvents[employeeId].ID) {
    //                     earliestClockInEvents[employeeId] = event;
    //                 }
    //             });
    //         }
    //     });
    
    //     // Преобразуем объект обратно в массив
    //     return Object.values(earliestClockInEvents);
    // };
    
    // // Применение функции к массиву availability
    // const updatedAvailability = filterByEarliestClockIn(availability);

    console.log(availability);
    const daysInMonth = Array.from({ length: new Date(new Date(selectedDate).getFullYear(), new Date(selectedDate).getMonth() + 1, 0).getDate() }, (_, i) => i + 1);

    const handleManagerChange = async (event) => {
        setIsChangingManager(true);
        setSelectedManager(parseInt(event.target.value));
        await new Promise(resolve => setTimeout(resolve, 500));
        setIsChangingManager(false);
    };

    const handleDateChange = (event) => {
        setLoading(true);
        setSelectedDate(event.target.value + "-01");
    };

    const filteredEmployees = selectedManager
        ? employees.filter(employee => employee.manager === selectedManager)
        : [];

    const uniqueManagers = [...new Set(employees.map(employee => employee.manager))];

    // Function to get the first clock-in and last clock-out for each day
    const getClockInOutTimes = (availability, employeeId, day) => {
        const eventsForDay = availability.filter(a => {
            const employeeIds = getPropertyValues(a.PROPERTY_912).map(String);
            const date = parseDate(getPropertyValues(a.PROPERTY_910)[0]);
            return employeeIds.includes(String(employeeId)) && date.getDate() === day;
        });

        // Find first clock-in (PROPERTY_915 === 6837) and last clock-out (PROPERTY_915 === 6838)
        const clockIns = eventsForDay.filter(event => getPropertyValues(event.PROPERTY_915)[0] === '6837');
        const clockOuts = eventsForDay.filter(event => getPropertyValues(event.PROPERTY_915)[0] === '6838');

        const firstClockIn = clockIns.length ? parseDate(getPropertyValues(clockIns[0].PROPERTY_910)[0]) : null;
        const lastClockOut = clockOuts.length ? parseDate(getPropertyValues(clockOuts[clockOuts.length - 1].PROPERTY_910)[0]) : null;

        return { firstClockIn, lastClockOut };
    };

    return (
        <main>
            <div className="filters">
                <a href="/" className="back-arrow"><ArrowLeftOutlined /></a>
                <select className="select-manager" onChange={handleManagerChange} value={selectedManager || ''}>
                    <option value=''>Select Manager</option>
                    {uniqueManagers.map((managerId, index) => (
                        <option key={index} value={managerId}>
                            {employees.find(employee => employee.value === managerId)?.label || `Manager ${managerId}`}
                        </option>
                    ))}
                </select>
                <input
                    type="month"
                    id="start"
                    name="start"
                    value={selectedDate.substring(0, 7)}
                    onChange={handleDateChange}
                />
            </div>
            <div className="calendar-container" style={{ gridTemplateColumns: `auto repeat(${daysInMonth.length}, 1fr)` }}>
                {loading ? (
                    <div>
                        <Spin className="spin-styles" tip="Loading" size="large" />
                    </div>
                ) : (
                    <>
                        <div className="calendar-header">
                            <div className="employee-header">Employee</div>
                            {daysInMonth.map(day => (
                                <div key={day} className={`day-header ${day === currentDay ? 'current-day' : ''}`}>{day}</div>
                            ))}
                        </div>
                        {filteredEmployees.map((employee, index) => (
                            <div key={index} className="calendar-row">
                                <div className="employee-name">{employee.label}</div>
                                {/* {daysInMonth.map(day => {
                                    const selectedDateObj = new Date(selectedDate);
                                    if (selectedDateObj.getFullYear() === currentYear &&
                                        selectedDateObj.getMonth() + 1 === currentMonth &&
                                        day > currentDay) {
                                        return (
                                            <div
                                                key={day}
                                                className="day-cell unavailable"
                                            ></div>
                                        );
                                    }

                                    const isAvailable = availability.some(a => {
                                        const employeeIds = getPropertyValues(a.PROPERTY_912).map(String);
                                        const date = parseDate(getPropertyValues(a.PROPERTY_910)[0]);
                                        return employeeIds.includes(String(employee.value)) && date.getDate() === day;
                                    });
                                    const isOutside = availability.some(a => {
                                        const employeeIds = getPropertyValues(a.PROPERTY_912).map(String);
                                        const date = parseDate(getPropertyValues(a.PROPERTY_910)[0]);
                                        const location = getPropertyValues(a.PROPERTY_918)[0];
                                        return employeeIds.includes(String(employee.value)) && date.getDate() === day && location === 'outside';
                                    });
                                    return (
                                        <div
                                            key={day}
                                            className={`day-cell ${day === currentDay ? 'current-day-cell' : ''} ${isAvailable ? 'available' : 'unavailable'}`}
                                        >
                                            {isOutside && <span>?</span>}
                                        </div>
                                    );
                                })} */}

                                {/* // In your cell rendering logic */}
                                {daysInMonth.map(day => {
                                    const selectedDateObj = new Date(selectedDate);
                                    if (selectedDateObj.getFullYear() === currentYear &&
                                        selectedDateObj.getMonth() + 1 === currentMonth &&
                                        day > currentDay) {
                                        return (
                                            <div
                                                key={day}
                                                className="day-cell unavailable"
                                            ></div>
                                        );
                                    }

                                    const { firstClockIn, lastClockOut } = getClockInOutTimes(availability, employee.value, day);
                                    const clockInTime = firstClockIn ? firstClockIn.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : '';
                                    const clockOutTime = lastClockOut ? lastClockOut.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : '';

                                    const isAvailable = availability.some(a => {
                                        const employeeIds = getPropertyValues(a.PROPERTY_912).map(String);
                                        const date = parseDate(getPropertyValues(a.PROPERTY_910)[0]);
                                        return employeeIds.includes(String(employee.value)) && date.getDate() === day;
                                    });
                                    const isOutside = availability.some(a => {
                                        const employeeIds = getPropertyValues(a.PROPERTY_912).map(String);
                                        const date = parseDate(getPropertyValues(a.PROPERTY_910)[0]);
                                        const location = getPropertyValues(a.PROPERTY_918)[0];
                                        return employeeIds.includes(String(employee.value)) && date.getDate() === day && location === 'outside';
                                    });

                                    return (
                                        <div
                                            key={day}
                                            className={`day-cell ${day === currentDay ? 'current-day-cell' : ''} ${clockInTime ? 'available' : 'unavailable'}`}
                                        >
                                            {clockInTime && <div className="cell-time-in">IN: {clockInTime}</div>}
                                            {clockOutTime && <div className="cell-time-out">OUT: {clockOutTime}</div>}
                                            {isOutside && <span className="unknown-location">?</span>}
                                        </div>
                                    );
                                })}


                            </div>
                        ))}
                    </>
                )}
            </div>
        </main>
    );
}